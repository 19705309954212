import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import theme, {bp, color} from 'settings/theme';

type Props = {
  sectionInView: boolean
};

// Component
const HomeGobiSmall: React.FC<Props> = (props) => {

  return (
    <StyledHomeGobiSmall sectionInView={props.sectionInView} className="homeGobiSmall">

      Gobi Ecosystem

    </StyledHomeGobiSmall>
  );
};

export default HomeGobiSmall;



const StyledHomeGobiSmall = styled.div<{sectionInView: boolean}>`

  display: block;

  @media (min-width: ${bp(3)}) {


  } 

`;
