import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';

import theme, {bp, color} from 'settings/theme';

type Props = {
  sectionInView: boolean
};

// Component
const HomeIntro: React.FC<Props> = (props) => {

  const router = useRouter();
  const layout = useLayout();

  useEffect(() => {
    if(props.sectionInView){
      layout.setActiveTab('intro', {scroll: false, changeRouterState: false})
    }
  }, [props.sectionInView]);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.08
      }
    }
  }

  const children = {
    hidden: { y: 40, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.35
      }
    }
  }

  return (
    <StyledHomeIntro id="homeSection-intro" sectionInView={props.sectionInView} className="homeIntro">

      <motion.div
        variants={container}
        initial="hidden"
        animate={props.sectionInView ? "show" : "hidden"}
      >
        <motion.div variants={children} className="homeIntro-section homeIntro-firstLine">
          We Build
        </motion.div>
        <motion.div variants={children} className="homeIntro-section homeIntro-text">
          Infrastructure Protocols
        </motion.div>
        <motion.div variants={children} className="homeIntro-section homeIntro-text">
          to Simplify Your Business
        </motion.div>
        <motion.div variants={children} className="homeIntro-section homeIntro-powered">
          Powered By <span><img src="/images/solana-text.svg" /></span>
        </motion.div>
        <motion.div variants={children} className="homeIntro-section homeIntro-explore">
          <button onClick={() => layout.setActiveTab('gobi')}>
            Explore <img src="/images/icons/icon-downright-black.svg" />
          </button>
        </motion.div>
      </motion.div>

    </StyledHomeIntro>
  );
};

export default HomeIntro;



const StyledHomeIntro = styled.div<{sectionInView: boolean}>`

  display: block;

  .homeIntro-section{
    display: block;
    font-size: 1.8rem;
    font-weight: 650;
    line-height: 1.3;
  }

  .homeIntro-firstLine{
    margin-bottom: 0.25rem;
    color: ${color.gray(40)};
    font-size: 1.5rem;
  }

  .homeIntro-powered{

    display: flex;
    align-items: center;
    margin-top: 1rem;
    color: ${color.gray(40)};
    font-size: 1.5rem;

    span{

      height: 1.4rem;
      margin-left: 0.5rem;

      img{
        width: auto;
        height: 1.2rem;
      }

    }

  }

  .homeIntro-explore{

    margin-top: 5rem;

    button{

      display: flex;
      align-items: center;
      height: 2.5rem;
      padding: 0 3rem;
      color: black;
      font-weight: 750;
      border-radius: 0.25rem;
      background-color: ${color.gray(80)};
      transition: all 0.2s ease-in-out;

      &:hover{
        background-color: ${color.gray(100)};
      }

      img{
        width: 0.6rem;
        height: 0.6rem;
        margin-left: 0.5rem;
      }

    }

  }

  @media (min-width: ${bp(3)}) {

    .homeIntro-section{
      font-size: 2.8rem;
    }

    .homeIntro-firstLine{
      margin-bottom: 0.5rem;
      font-size: 2rem;
    }

    .homeIntro-powered{
      margin-top: 1rem;
      font-size: 1.8rem;
    }

  } 

`;
