import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';

import theme, { bp, color } from 'settings/theme';

type Props = {
  sectionInView: boolean
};

// Component
const HomeMore: React.FC<Props> = (props) => {

  const router = useRouter();
  const layout = useLayout();

  useEffect(() => {
    if (props.sectionInView) {
      layout.setActiveTab('more', { scroll: false, changeRouterState: false })
    }
  }, [props.sectionInView]);

  return (
    <StyledHomeMore id="homeSection-more" sectionInView={props.sectionInView} className="homeMore">

      <div className="homeMore-projects">

        {/* Colosseum */}
        <div className="homeMore-project">
          <div className="homeMore-project-image">
            <img src="/images/logos/logo-white-colosseum.svg" />
          </div>
          <div className="homeMore-project-titleText">
            <div className="homeMore-project-title">
              COLOSSEUM
            </div>
            <div className="homeMore-project-text">
              A first of its kind fully on-chain sportsbook that settles lines on the Solana blockchain while giving full custodial control to the user. Simply connect & bet, and the smart contract auto sends you your winnings!
            </div>
          </div>
          <div className="homeMore-project-button homeMore-project-button--colosseum">
            <Link href="https://colosseum.so" target="_blank">
              Explore <img src="/images/icons/icon-upright-black.svg" />
            </Link>
          </div>
        </div>

        {/* HighJinx */}
        <div className="homeMore-project">
          <div className="homeMore-project-image">
            <img src="/images/logos/logo-white-highjinx.svg" />
          </div>
          <div className="homeMore-project-titleText">
            <div className="homeMore-project-title">
              HIGHJINX
            </div>
            <div className="homeMore-project-text">
              Hidden in the vast, borderless desert sand, comes a brand that usually will get you banned. We like a giggle or two, and our stuff isn’t for the meek, for we will do what makes us laugh — full tongue in cheek.
            </div>
          </div>
          <div className="homeMore-project-button">
            <Link href="https://ormuz.gobi.so/shop/highjinx" target="_blank">
              Explore <img src="/images/icons/icon-upright-black.svg" />
            </Link>
          </div>
        </div>

        {/* Eden */}
        <div className="homeMore-project">
          <div className="homeMore-project-image">
            <img src="/images/logos/logo-white-eden.svg" />
          </div>
          <div className="homeMore-project-titleText">
            <div className="homeMore-project-title">
              EDEN
            </div>
            <div className="homeMore-project-text">
              With the full legalization of cannabis in Thailand, Eden is the first cannabis grower index on the Solana blockchain that guarantees consistency from lineage to harvest in an opaque market, filled with unknowns.
            </div>
          </div>
          <div className="homeMore-project-button homeMore-project-button--disabled">
            <button>
              Coming Soon
            </button>
          </div>
        </div>

        {/* Puzzles */}
        <div className="homeMore-project">
          <div className="homeMore-project-image">
            <img src="/images/logos/logo-white-puzzles.svg" />
          </div>
          <div className="homeMore-project-titleText">
            <div className="homeMore-project-title">
              PUZZLES
            </div>
            <div className="homeMore-project-text">
              Puzzles is a content channel that creates motion graphic style explainers focusing on business, markets & crypto. It is built & run by Gobi Labs.
            </div>
          </div>
          <div className="homeMore-project-button">
            <Link href="https://puzzles.so" target="_blank">
              Explore <img src="/images/icons/icon-upright-black.svg" />
            </Link>
          </div>
        </div>

      </div>

    </StyledHomeMore>
  );

};

export default HomeMore;



const StyledHomeMore = styled.div<{ sectionInView: boolean }>`

  display: block;

  .homeMore-projects{

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;

    .homeMore-project{

      display: flex;
      flex-direction: column;
      width: 100%;

      .homeMore-project-image{

        display: flex;
        align-items: center;
        justify-content: center;
        width: 40%;
        margin-left: auto;
        margin-right: auto;

        img{
          width: 100%;
          max-width: 8rem;
          height: auto;
        }

      }

      .homeMore-project-title{
        margin-top: 1rem;
        font-size: 1.75rem;
        font-weight: 600;
      }

      .homeMore-project-text{
        margin-top: 1rem;
        color: ${color.gray(75)};
        font-size: 0.875rem;
      }

      .homeMore-project-button{
      
        margin-top: 1.5rem;

        button, a{

          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          min-width: 12rem;
          height: 2.5rem;
          padding: 0 3rem;
          color: black;
          font-size: 1rem;
          font-weight: 750;
          border-radius: 0.25rem;
          white-space: nowrap;
          background-color: ${color.gray(80)};
          transition: all 0.2s ease-in-out;

          &:hover{
            background-color: ${color.gray(100)};
          }

          img{
            width: 0.6rem;
            height: 0.6rem;
            margin-left: 0.5rem;
          }

        }

        &.homeMore-project-button--disabled{

          button, a{

            cursor: default;
            opacity: 0.5;

            &:hover{
              background-color: ${color.gray(80)};
            }

          }

        }

      
      }
      

    }

  }

  @media (min-width: ${bp(3)}) {

    .homeMore-projects{

      gap: 6rem;

      .homeMore-project{

        flex-direction: row;

        .homeMore-project-image{

          width: auto;
          margin: 0;

          img{
            width: auto;
            height: 6rem;
          }

        }

        .homeMore-project-titleText{
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 2rem;
        }

        .homeMore-project-title{
          margin: 0;
          font-size: 1.5rem;
        }

        .homeMore-project-text{
          margin-top: 0.5rem;
          font-size: 1rem;
        }

        .homeMore-project-button{
        
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          margin-left: 2rem;
        
        }
        

      }

    }

  } 

  @media (min-width: ${bp(5)}) {

    .homeMore-projects{

      .homeMore-project{

        .homeMore-project-image{

          img{
            height: 8rem;
          }

        }

        .homeMore-project-titleText{
          margin-left: 3rem;
        }

        .homeMore-project-title{
          font-size: 2rem;
        }

        .homeMore-project-text{
          margin-top: 0.75rem;
        }

        .homeMore-project-button{
          margin-left: 5rem;
        }
        

      }

    }


  }

`;
