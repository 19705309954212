import { ReactElement, useEffect , useState } from 'react';
import Head from 'next/head';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { createServerSideHelpers } from '@trpc/react-query/server';
import { DehydratedState } from '@tanstack/react-query';
import { createContext } from 'server/context';

import superJson from 'utils/superJson';
import { appRouter } from 'server/routers/_app';

import MainLayout from 'components/layout/mainLayout';
import MaxWidthWrapper from 'components/layout/maxWidthWrapper';
import HomeSection from 'components/home/homeSection';

import HomeIntro from 'components/home/intro/homeIntro';
import HomeGobi from 'components/home/gobi/homeGobi';
import HomeOasis from 'components/home/oasis/homeOasis';
import HomeOrmuz from 'components/home/ormuz/homeOrmuz';
import HomeMerchants from 'components/home/merchants/homeMerchants';
import HomeMore from 'components/home/more/homeMore';


import useLayout from 'providers/layout/layoutProvider';

import theme, { bp, color } from 'settings/theme';


type Props = {
  templateData: TemplateData,
  trpcState: DehydratedState
};

const HomePage: NextPageExtended<Props> = (props) => {

  const layout = useLayout();
  const router = useRouter();

  useEffect(() => {

    if(!router.isReady) return;

    const tabName = router.asPath.replace('/', '');

    layout.setActiveTab(tabName);

  }, [router.isReady]);

  return (
    <>

      <Head>
        <title>Gobi</title>
      </Head>

      <StyledHomePage className="homePage">

        <MaxWidthWrapper>
          
          {/* Intro  */}
          <HomeSection>
            {({sectionInView}) => (
              <HomeIntro sectionInView={sectionInView} />
            )}
          </HomeSection>
          

          {/* Gobi Ecosystem */}
          <HomeSection>
            {({sectionInView}) => (
              <HomeGobi sectionInView={sectionInView} />
            )}
          </HomeSection>


          {/* Oasis */}
          <HomeSection>
            {({sectionInView}) => (
              <HomeOasis sectionInView={sectionInView} />
            )}
          </HomeSection>


          {/* Ormuz */}
          <HomeSection>
            {({sectionInView}) => (
              <HomeOrmuz sectionInView={sectionInView} />
            )}
          </HomeSection>


          {/* Merchants */}
          <HomeSection>
            {({sectionInView}) => (
              <HomeMerchants sectionInView={sectionInView} />
            )}
          </HomeSection>


          {/* More */}
          <HomeSection>
            {({sectionInView}) => (
              <HomeMore sectionInView={sectionInView} />
            )}
          </HomeSection>


        </MaxWidthWrapper>
        
      </StyledHomePage>

    </>
  );

}

export const getStaticProps = async ({ params }) => {

  const ssg = createServerSideHelpers({
    router: appRouter,
    ctx: await createContext(),
    transformer: superJson
  });

  return {
    props: {
      trpcState: ssg.dehydrate(),
    },
    revalidate: 1
  };

};

export async function getStaticPaths() {
  return {
    paths: [
      { params: { tab: [''] } },
      { params: { tab: ['gobi'] } },
      { params: { tab: ['oasis'] } },
      { params: { tab: ['ormuz'] } },
      { params: { tab: ['merchants'] } },
      { params: { tab: ['more'] } },
    ],
    fallback: false
  };
}

HomePage.getLayout = (page: ReactElement) => {
  return (
    <MainLayout>
      {page}
    </MainLayout>
  )
}

export default HomePage;

const StyledHomePage = styled.div`

  display: block;

  @media (min-width: ${bp(3)}) {

  }

`;