import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';

import theme, {bp, color} from 'settings/theme';


type Props = {
  sectionInView: boolean
};

// Component
const HomeGobiLarge: React.FC<Props> = (props) => {

  const layout = useLayout();

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1
      }
    }
  }

  const children = {
    hidden: { opacity: 0, scale: 0.5 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.25
      }
    }
  }

  return (
    <StyledHomeGobiLarge sectionInView={props.sectionInView} className="homeGobiLarge">

      <h2 className="homeGobiLarge-title">The Gobi Ecosystem</h2>

      {/* Wrapper */}
      <motion.div 
        variants={container}
        initial="hidden"
        animate={props.sectionInView ? "show" : "hidden"} 
        className="homeGobiLarge-box"
      >

        {/* Outer */}
        <motion.div variants={children} className="homeGobiLarge-outerBox">
          <div className="homeGobiLarge-outerCircle">

            {/* Eden */}
            <div className="homeGobiLarge-box-content homeGobiLarge-eden">
              <div className="homeGobiLarge-logoBox" onClick={() => layout.setActiveTab('more')}>
                <div className="homeGobiLarge-logo">
                  <img src="/images/logos/logo-white-eden.svg" alt="Eden Logo" />
                </div>
                <div className="homeGobiLarge-logoText">
                  EDEN
                </div>
              </div>
            </div>

            {/* Puzzles */}
            <div className="homeGobiLarge-box-content homeGobiLarge-puzzles">
              <div className="homeGobiLarge-logoBox" onClick={() => layout.setActiveTab('more')}>
                <div className="homeGobiLarge-logo">
                  <img src="/images/logos/logo-white-puzzles.svg" alt="Puzzles Logo" />
                </div>
                <div className="homeGobiLarge-logoText">
                  PUZZLES
                </div>
              </div>
            </div>
            
            {/* Colosseum */}
            <div className="homeGobiLarge-box-content homeGobiLarge-colosseum">
              <div className="homeGobiLarge-logoBox" onClick={() => layout.setActiveTab('more')}>
                <div className="homeGobiLarge-logo">
                  <img src="/images/logos/logo-white-colosseum.svg" alt="Colosseum Logo" />
                </div>
                <div className="homeGobiLarge-logoText">
                  COLOSSEUM
                </div>
              </div>
            </div>

            {/* HighJinx */}
            <div className="homeGobiLarge-box-content homeGobiLarge-highjinx">
              <div className="homeGobiLarge-logoBox" onClick={() => layout.setActiveTab('more')}>
                <div className="homeGobiLarge-logo">
                  <img src="/images/logos/logo-white-highjinx.svg" alt="HighJinx Logo" />
                </div>
                <div className="homeGobiLarge-logoText">
                  HIGHJINX
                </div>
              </div>
            </div>

          </div>
        </motion.div>

        {/* Inner */}
        <motion.div variants={children} className="homeGobiLarge-innerBox">
          <div className="homeGobiLarge-innerCircle">

            {/* Ormuz */}
            <div className="homeGobiLarge-box-content homeGobiLarge-ormuz">
              <div className="homeGobiLarge-logoBox" onClick={() => layout.setActiveTab('ormuz')}>
                <div className="homeGobiLarge-logo">
                  <img src="/images/logos/logo-white-ormuz.svg" alt="Ormuz Logo" />
                </div>
                <div className="homeGobiLarge-logoText">
                  ORMUZ
                </div>
              </div>
            </div>
            
            {/* Oasis */}
            <div className="homeGobiLarge-box-content homeGobiLarge-oasis">
              <div className="homeGobiLarge-logoBox" onClick={() => layout.setActiveTab('oasis')}>
                <div className="homeGobiLarge-logo">
                  <img src="/images/logos/logo-white-oasis.svg" alt="Oasis Logo" />
                </div>
                <div className="homeGobiLarge-logoText">
                  OASIS
                </div>
              </div>
            </div>

            {/* Merchants */}
            <div className="homeGobiLarge-box-content homeGobiLarge-merchants">
              <div className="homeGobiLarge-logoBox" onClick={() => layout.setActiveTab('merchants')}>
                <div className="homeGobiLarge-logo">
                  <img src="/images/logos/logo-white-merchants.svg" alt="Merchants Logo" />
                </div>
                <div className="homeGobiLarge-logoText">
                  MERCHANTS
                </div>
              </div>
            </div>

          </div>
        </motion.div>

        {/* Gobi */}
        <motion.div variants={children} className="homeGobiLarge-logoBox homeGobiLarge-gobi">
          <div className="homeGobiLarge-logo">
            <img src="/images/logos/logo-colored-gobi.svg" alt="Gobi Logo" />
          </div>
          <div className="homeGobiLarge-logoText">
            GOBI LABS
          </div>
        </motion.div>

      </motion.div>

    </StyledHomeGobiLarge>
  );
};

export default HomeGobiLarge;



const StyledHomeGobiLarge = styled.div<{sectionInView: boolean}>`

  display: block;
  width: 100%;

  .homeGobiLarge-box{
    position: relative;
    width: 100%;
    max-width: 50rem;
    aspect-ratio: 1/1;
    margin: 0 auto;
    transition: translate 0.75s ease-out;
    translate: ${props => props.sectionInView ? '0 0rem' : '0 20rem'};
  }

  .homeGobiLarge-title{
    width: 100%;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 2rem;
    transition: all 0.15s ease-out;
    translate: ${props => props.sectionInView ? '0 0rem' : '0 -12rem'};
    opacity: ${props => props.sectionInView ? 1 : 0};
  }

  .homeGobiLarge-logoBox{

    z-index: 1;
    position: absolute;
    top: 0;
    left: 50%;
    translate: -50% -50%;
    width: 2.25rem;
    height: 2.25rem;
    pointer-events: all;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.20s ease-out, scale 0.20s ease-out;

    &:hover{
      opacity: 1;
      scale: 1.1;
    }

    &::after{
      z-index: -1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: ${color.gray(15)};
      transform: scale(1.4);
    }

    .homeGobiLarge-logo{

      width: 100%;
      height: 100%;

      img{
        width: 100%;
        height: 100%;
      }

    }

    .homeGobiLarge-logoText{
      position: absolute;
      bottom: -1.5rem;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100%;
      white-space: nowrap;
      height: 1.5rem;
      padding: 0 1rem;
      color: ${color.gray(66)};
      font-size: 0.675rem;
      border-radius: 0.75rem;
      translate: -50% 0;
      background-color: ${color.gray(15)};
    }


  }

  .homeGobiLarge-gobi{
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
  }

  .homeGobiLarge-box-content{
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .homeGobiLarge-innerBox{

    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    translate: -50% -50%;

    .homeGobiLarge-innerCircle{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px dashed ${color.gray(50)};
      border-radius: 50%;
    }

  }

  .homeGobiLarge-ormuz{

    rotate: 0;
    animation: rotate 20s linear infinite;

    .homeGobiLarge-logoBox{
      rotate: 0;
      animation: rotate 20s linear reverse infinite;
    }

  }

  @keyframes rotate { 100% { rotate: 360deg; } }

  .homeGobiLarge-oasis{

    animation: rotateOasis 20s linear infinite;

    .homeGobiLarge-logoBox{
      animation: rotateOasisReverse 20s linear infinite;
    }

    @keyframes rotateOasis { 0% { rotate: 120deg; } 100% { rotate: 480deg; } }
    @keyframes rotateOasisReverse { 0% { rotate: 240deg; } 100% { rotate: -120deg; } }

  }

  
  .homeGobiLarge-merchants{

    animation: rotateMerchants 20s linear infinite;

    .homeGobiLarge-logoBox{
      animation: rotateMerchantsReverse 20s linear infinite;
    }

    @keyframes rotateMerchants { 0% { rotate: -120deg; } 100% { rotate: 240deg; } }
    @keyframes rotateMerchantsReverse { 0% { rotate: 120deg; } 100% { rotate: -240deg; } }

  }

  .homeGobiLarge-outer{
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .homeGobiLarge-outerBox{

    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    translate: -50% -50%;

    .homeGobiLarge-outerCircle{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px dashed ${color.gray(50)};
      border-radius: 50%;
    }

  }

  .homeGobiLarge-eden{

    animation: rotateEden 40s linear infinite;

    .homeGobiLarge-logoBox{
      animation: rotateEdenReverse 40s linear reverse infinite;
    }

  }

  @keyframes rotateEden { 0% { rotate: 45deg; } 100% { rotate: -315deg; } }
  @keyframes rotateEdenReverse { 0% { rotate: 315deg; } 100% { rotate: -45deg; } }


  .homeGobiLarge-puzzles{

    animation: rotatePuzzles 40s linear infinite;

    .homeGobiLarge-logoBox{
      animation: rotatePuzzlesReverse 40s linear reverse infinite;
    }

  }

  @keyframes rotatePuzzles { 0% { rotate: 135deg; } 100% { rotate: -225deg; } }
  @keyframes rotatePuzzlesReverse { 0% { rotate: 225deg; } 100% { rotate: -135deg; } }


  .homeGobiLarge-colosseum{

    animation: rotateColosseum 40s linear infinite;

    .homeGobiLarge-logoBox{
      animation: rotateColosseumReverse 40s linear infinite;
    }

    @keyframes rotateColosseum { 0% { rotate: 225deg; } 100% { rotate: -135deg; } }
    @keyframes rotateColosseumReverse { 0% { rotate: -225deg; } 100% { rotate: 135deg; } }

  }

  
  .homeGobiLarge-highjinx{

    rotate: -45deg;
    animation: rotateHighJinx 40s linear infinite;

    .homeGobiLarge-logoBox{
      rotate: -315deg;
      animation: rotateHighJinxReverse 40s linear infinite;
    }

    @keyframes rotateHighJinx { 0% { rotate: 315deg; } 100% { rotate: -45deg; } }
    @keyframes rotateHighJinxReverse { 0% { rotate: -315deg; } 100% { rotate: 45deg; } }

  }


  @media (min-width: ${bp(3)}) {

    .homeGobiLarge-title{
      margin-top: 4rem;
      margin-bottom: 0;
      font-size: 1.8rem;
    }

    .homeGobiLarge-logoBox{
      width: 3.5rem;
      height: 3.5rem;

      .homeGobiLarge-logoText{
        bottom: -2.25rem;
        height: 2rem;
        padding: 0 1rem;
        font-size: 0.875rem;
        border-radius: 1rem;
      }

    }

    .homeGobiLarge-innerBox{

      width: 45%;
      height: 45%;

      .homeGobiLarge-innerCircle{
        border-style: none;
        background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='100%' ry='100%' stroke='%23909090FF' stroke-width='1' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      }

    }

    .homeGobiLarge-outerBox{

      width: 80%;
      height: 80%;

      .homeGobiLarge-outerCircle{
        border-style: none;
        background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='100%' ry='100%' stroke='%23909090FF' stroke-width='1' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      }
      
    }

  } 

`;
