import React, { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import Slider from "react-slick";

import theme, {bp, color} from 'settings/theme';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Props = {};

// Component
const HomeMerchantsCarousel: React.FC<Props> = (props) => {

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    easing: 'ease-out',
    slidesPerRow: 1,
    variableWidth: true,
    centerMode: true
  };

  return (
    <StyledHomeMerchantsCarousel className="homeMerchantsCarousel">

      <div className="homeMerchantsCarousel-spinner">

        <Slider {...settings}>
          <div className="homeMerchantsCarousel-slide">
            <div className="homeMerchantsCarousel-slide-inner">
              <Image src="/images/merchant-nfts/1.jpg" alt="Merchant 1" width={400} height={400} />
            </div>
          </div>
          <div className="homeMerchantsCarousel-slide">
            <div className="homeMerchantsCarousel-slide-inner">
              <Image src="/images/merchant-nfts/2.jpg" alt="Merchant 2" width={400} height={400} />
            </div>
          </div>
          <div className="homeMerchantsCarousel-slide">
            <div className="homeMerchantsCarousel-slide-inner">
              <Image src="/images/merchant-nfts/3.jpg" alt="Merchant 3" width={400} height={400} />
            </div>
          </div>
          <div className="homeMerchantsCarousel-slide">
            <div className="homeMerchantsCarousel-slide-inner">
              <Image src="/images/merchant-nfts/4.jpg" alt="Merchant 4" width={400} height={400} />
            </div>
          </div>
          <div className="homeMerchantsCarousel-slide">
            <div className="homeMerchantsCarousel-slide-inner">
              <Image src="/images/merchant-nfts/5.jpg" alt="Merchant 5" width={400} height={400} />
            </div>
          </div>
          <div className="homeMerchantsCarousel-slide">
            <div className="homeMerchantsCarousel-slide-inner">
              <Image src="/images/merchant-nfts/6.jpg" alt="Merchant 6" width={400} height={400} />
            </div>
          </div>
          <div className="homeMerchantsCarousel-slide">
            <div className="homeMerchantsCarousel-slide-inner">
              <Image src="/images/merchant-nfts/7.jpg" alt="Merchant 7" width={400} height={400} />
            </div>
          </div>
          <div className="homeMerchantsCarousel-slide">
            <div className="homeMerchantsCarousel-slide-inner">
              <Image src="/images/merchant-nfts/8.jpg" alt="Merchant 8" width={400} height={400} />
            </div>
          </div>
          <div className="homeMerchantsCarousel-slide">
            <div className="homeMerchantsCarousel-slide-inner">
              <Image src="/images/merchant-nfts/9.jpg" alt="Merchant 9" width={400} height={400} />
            </div>
          </div>
        </Slider>

      </div>

    </StyledHomeMerchantsCarousel>
  );
};

export default HomeMerchantsCarousel;



const StyledHomeMerchantsCarousel = styled.div<{}>`

  --spinner-height: 10rem;

  position: relative;
  display: block;
  width: 100%;
  height: 60vw;

  .homeMerchantsCarousel-spinner{
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% + 3rem);
    transform: translateX(-50%);
    overflow: hidden;
  }

  .homeMerchantsCarousel-slide{

    padding: 0.5rem;

    .homeMerchantsCarousel-slide-inner{
      border-radius: 1rem;
      overflow: hidden;
    }

    img{
      width: 60vw;
      height: auto;
      filter: grayscale(100%);
      transition: filter 1.5s ease;
    }

  }

  .slick-slide{

    &.slick-center{
      img{
        filter: grayscale(0%);
      }
    }

  }


  @media (min-width: ${bp(2)}) {

    .homeMerchantsCarousel-spinner{
      width: calc(100% + 5rem);
    }

  } 

  @media (min-width: ${bp(3)}) {

  } 

`;
