import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import HomeMerchantsCarousel from 'components/home/merchants/homeMerchantsCarousel';
import HomeMerchantsNfts from 'components/home/merchants/homeMerchantsNfts';

import useLayout from 'providers/layout/layoutProvider';

import theme, {bp, color} from 'settings/theme';

type Props = {
  sectionInView: boolean
};

// Component
const HomeMerchants: React.FC<Props> = (props) => {

  const router = useRouter();
  const layout = useLayout();

  useEffect(() => {
    if(props.sectionInView){
      layout.setActiveTab('merchants', {scroll: false, changeRouterState: false})
    }
  }, [props.sectionInView]);

  return (
    <StyledHomeMerchants id="homeSection-merchants" sectionInView={props.sectionInView} className="homeMerchants">

      <div className="homeMerchants-title">
        <h2>Become a Gobi Merchant</h2>
      </div>

      <div className="homeMerchants-graphic">
        <div className="homeMerchants-graphic-small">
          <HomeMerchantsCarousel />
        </div>
        <div className="homeMerchants-graphic-large">
          <HomeMerchantsNfts />
        </div>
      </div>

      <div className="homeMerchants-details">
        
        <div className="homeMerchants-subtitle">
          Merchants NFT Collection
        </div>

        <div className="homeMerchants-intro">
          A community of Merchants supporting Gobi Labs & taxing the ecosystem.
        </div>

        <div className="homeMerchants-text">
          Merchants are our de-facto pfp collection for all of our products. They act as guides for new entrants into the desert, and earn rewards by taxing every product within the Gobi Labs ecosystem through monthly Guild Ceremonies. 
        </div>

        <div className="homeMerchants-button">
          <Link href="https://www.tensor.trade/trade/merchants_gobi" target="_blank">
            Join <img src="/images/icons/icon-upright-white.svg" />
          </Link>
        </div>

      </div>

    </StyledHomeMerchants>
  );

};

export default HomeMerchants;



const StyledHomeMerchants = styled.div<{sectionInView: boolean}>`

  display: grid;
  grid-template-areas: "title" "graphic" "details";
  width: 100%;
  padding-top: 6rem;

  .homeMerchants-title{

    grid-area: title;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;

    h2{
      font-size: 2.5rem;
      line-height: 1.1;
      text-align: center;
    }

  }

  .homeMerchants-graphic{

    grid-area: graphic;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .homeMerchants-graphic-small{
      width: 100%;
    }

    .homeMerchants-graphic-large{
      display: none;
    }

  }

  .homeMerchants-details{
    grid-area: details;
  }

  .homeMerchants-subtitle{
    margin-top: 3rem;
    font-size: 2rem;
    font-weight: 600;
  }

  .homeMerchants-intro{
    margin-top: 0.75rem;
    font-size: 1.25rem;
    line-height: 1.2;
  }

  .homeMerchants-text{
    margin-top: 0.75rem;
    color: ${color.gray(66)};
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.4;
  }

  .homeMerchants-button{

    margin-top: 1.5rem;

    a{

      display: inline-flex;
      align-items: center;
      height: 2.5rem;
      padding: 0 3rem;
      color: white;
      font-weight: 750;
      border-radius: 0.25rem;
      background-color: ${color.merchantsYellow(40)};
      transition: all 0.2s ease-in-out;

      &:hover{
        background-color: ${color.merchantsYellow(50)};
      }

      img{
        width: 0.6rem;
        height: 0.6rem;
        margin-left: 0.5rem;
      }

    }

  }

  @media (min-width: ${bp(2)}) {

    .homeMerchants-title{
      margin-bottom: 2rem;
    }

  }

  @media (min-width: ${bp(3)}) {

    grid-template-areas: "title graphic" "details graphic";
    grid-template-rows: min-content 1fr;

    .homeMerchants-title{

      max-width: 100%;
      margin: 0;

      h2{
        font-size: 4rem;
        text-align: left;
      }

    }

    .homeMerchants-graphic{

      .homeMerchants-graphic-small{
        display: none;
      }

      .homeMerchants-graphic-large{
        display: block;
      }

    }

    .homeMerchants-subtitle{
      margin-top: 2rem;
    }

  }


  @media (min-width: ${bp(4)}) {

    .homeMerchants-title{

      h2{
        font-size: 4rem;
        line-height: 1;
      }

    }

    .homeMerchants-graphic{
      width: 20rem;
    }

  }


  @media (min-width: ${bp(5)}) {

    .homeMerchants-title{

      h2{
        font-size: 6rem;
      }

    }

    .homeMerchants-graphic{

      justify-content: flex-end;
      width: 28rem;
      margin-left: 3rem;

      .homeMerchants-dots{
        width: 180%;
      } 

      .homeMerchants-phone{
        width: 80%;
      }

    }

  }

`;
