import React, { useState, useEffect, useRef, useCallback } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import Particles from "react-tsparticles";
import type { Container, Engine } from "tsparticles-engine";
import type { ISourceOptions } from 'tsparticles-engine';
import { loadFull  } from "tsparticles";
import dots1Options from "./dots1.json";
import dots2Options from "./dots2.json";
import dots3Options from "./dots3.json";

import useLayout from 'providers/layout/layoutProvider';

import theme, {bp, color} from 'settings/theme';

type Props = {
  sectionInView: boolean
};

// Component
const HomeOasis: React.FC<Props> = (props) => {

  const router = useRouter();
  const layout = useLayout();


  // Top Menu
  useEffect(() => {
    if(props.sectionInView){
      layout.setActiveTab('oasis', {scroll: false, changeRouterState: false})
    }
  }, [props.sectionInView]);


  // Scroll Position
  const [scrollPosition, setScrollPosition] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    
    const handleScroll = () => {

      if(!ref.current){ return; }

      // get viewport height
      const vh = window.innerHeight;

      // Get position of Y midpoint of element
      const rect = ref.current?.getBoundingClientRect();
      const midPoint = rect?.top + (rect?.height / 2);
      
      // Get position of Y midpoint of viewport
      const midPointViewport = (vh / 2);

      // Get element offset from center of viewport
      const elY = midPoint - midPointViewport;

      setScrollPosition(elY);
      
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  // Particles
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);


  return (
    <StyledHomeOasis ref={ref} id="homeSection-oasis" sectionInView={props.sectionInView} className="homeOasis">

      <div className="homeOasis-title">
        <h2>Welcome to The Oasis</h2>
      </div>

      <div className="homeOasis-graphic">
        <div className="homeOasis-graphic-inner">
          <div className="homeOasis-dots">
            <div className="homeOasis-dots-layer homeOasis-dots1" style={{translate: `0 ${scrollPosition * 0.6}px`}}>
              <Particles
                id="dots1"
                init={particlesInit}
                options={dots1Options as ISourceOptions}
              />
            </div>
            <div className="homeOasis-dots-layer homeOasis-dots2" style={{translate: `0 ${scrollPosition * 0.4}px`}}>
              <Particles
                id="dots2"
                init={particlesInit}
                options={dots2Options as ISourceOptions}
              />
            </div>
            <div className="homeOasis-dots-layer homeOasis-dots3" style={{translate: `0 ${scrollPosition * 0.2}px`}}>
              <Particles
                id="dots3"
                init={particlesInit}
                options={dots3Options as ISourceOptions}
              />
            </div>
          </div>
          <div className="homeOasis-phone">
            <div className="homeOasis-phone-image">
              <Image src="/images/phone.png" width="664" height="1440" alt="phone" />
            </div>
            <div className="homeOasis-phone-shadow"></div>
          </div>
        </div>
      </div>

      <div className="homeOasis-details">
        
        <div className="homeOasis-subtitle">
          Oasis Swap
        </div>

        <div className="homeOasis-intro">
          A B2B DEX offering custom backend control for token & pool issuers.
        </div>

        <div className="homeOasis-text">
          The Oasis is a first of its kind DEX that prioritizes business needs. We offer a full backend customization in terms of fees, slippage & more so any business can implement any part of the DEX (Swap, LP, SSLP Orderbook) natively through a smart contract.
        </div>

        <div className="homeOasis-button">
          <Link href="https://oasis.gobi.so/" target="_blank">
            Explore <img src="/images/icons/icon-upright-white.svg" />
          </Link>
        </div>

      </div>

    </StyledHomeOasis>
  );

};

export default HomeOasis;



const StyledHomeOasis = styled.div<{sectionInView: boolean}>`

  display: grid;
  grid-template-areas: "title" "graphic" "details";
  width: 100%;
  padding-top: 6rem;

  .homeOasis-title{

    grid-area: title;
    max-width: 66.6%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;

    h2{
      font-size: 2.5rem;
      line-height: 1.1;
      text-align: center;
    }

  }

  .homeOasis-graphic{

    grid-area: graphic;
    max-width: 24rem;
    margin-left: auto;
    margin-right: auto;

    .homeOasis-graphic-inner{
      position: relative;
    }

    .homeOasis-dots{

      z-index: -1;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 150%;
      aspect-ratio: 1/1;
      border-radius: 50%;
      translate: -50% -50%;

      .homeOasis-dots-layer{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        mask-image: radial-gradient(circle at center, white 0%, transparent 69%);
        /* animation: rotateOasis 30s linear infinite; */
      }

      @keyframes rotateOasis { 0% { rotate: 120deg; } 100% { rotate: 480deg; } }

    }

    .homeOasis-phone{

      width: 60%;
      margin-left: auto;
      margin-right: auto;

      .homeOasis-phone-image{
        img{
          width: 100%;
          height: auto;
          opacity: 1;
          animation: float 6s ease-in-out infinite;
        }

        @keyframes float {
          0% {
            transform: translatey(10px);
          }
          50% {
            transform: translatey(-10px);
          }
          100% {
            transform: translatey(10px);
          }
        }

      }

      .homeOasis-phone-shadow{
        position: relative;
        z-index: -1;
        width: 100%;
        aspect-ratio: 8/1;
        background-image: radial-gradient(ellipse at center, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 75%);
        background-size: cover;
        animation: shadow 6s ease-in-out infinite;
      }

      @keyframes shadow {
        0% {
          transform: scale(1.25);
        }
        50% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1.25);
        }
      }

    }

  }

  .homeOasis-details{
    grid-area: details;
  }

  .homeOasis-subtitle{
    margin-top: 3rem;
    font-size: 2rem;
    font-weight: 600;
  }

  .homeOasis-intro{
    margin-top: 0.75rem;
    font-size: 1.25rem;
    line-height: 1.2;
  }

  .homeOasis-text{
    margin-top: 0.75rem;
    color: ${color.gray(66)};
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.4;
  }

  .homeOasis-button{

    margin-top: 1.5rem;

    a{

      display: inline-flex;
      align-items: center;
      height: 2.5rem;
      padding: 0 3rem;
      color: white;
      font-weight: 750;
      border-radius: 0.25rem;
      background-color: ${color.oasisBlue(60)};
      transition: all 0.2s ease-in-out;

      &:hover{
        background-color: ${color.oasisBlue(70)};
      }

      img{
        width: 0.6rem;
        height: 0.6rem;
        margin-left: 0.5rem;
      }

    }

  }

  @media (min-width: ${bp(2)}) {

    .homeOasis-title{
      margin-bottom: 4rem;
    }

  }

  @media (min-width: ${bp(3)}) {

    grid-template-areas: "title graphic" "details graphic";
    grid-template-rows: min-content 1fr;

    .homeOasis-title{

      max-width: 100%;
      margin: 0;

      h2{
        font-size: 4rem;
        text-align: left;
      }

    }

    .homeOasis-graphic{
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: inherit;
      width: 16rem;
    }

    .homeOasis-subtitle{
      margin-top: 2rem;
    }

  }


  @media (min-width: ${bp(4)}) {

    .homeOasis-title{

      h2{
        font-size: 5rem;
        line-height: 1;
      }

    }

    .homeOasis-graphic{
      width: 20rem;
    }

  }


  @media (min-width: ${bp(5)}) {

    .homeOasis-title{

      h2{
        font-size: 7rem;
      }

    }

    .homeOasis-graphic{

      justify-content: flex-end;
      width: 32rem;

      .homeOasis-dots{
        width: 180%;
      } 

      .homeOasis-phone{
        width: 80%;
      }

    }

  }

`;
