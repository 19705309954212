import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';

import theme, {bp, color} from 'settings/theme';

type Props = {
  sectionInView: boolean
};

// Component
const HomeOrmuz: React.FC<Props> = (props) => {

  const router = useRouter();
  const layout = useLayout();

  useEffect(() => {
    if(props.sectionInView){
      layout.setActiveTab('ormuz', {scroll: false, changeRouterState: false})
    }
  }, [props.sectionInView]);

  return (
    <StyledHomeOrmuz id="homeSection-ormuz" sectionInView={props.sectionInView} className="homeOrmuz">

      <div className="homeOrmuz-video">
        <video autoPlay loop muted playsInline>
          <source src="ormuz-background-gray.mp4" type="video/mp4" />
        </video>
      </div>

      {/* Title */}
      <div className="homeOrmuz-title">
        <h2>A Web3<br />Retail Model</h2>
      </div>

      {/* Graphic */}
      <div className="homeOrmuz-graphic">
        <div className="homeOrmuz-graphic-small">
          <Image src="/images/ormuz-graphic-small.png" width={600} height={751} alt="Devices" />
        </div>
        <div className="homeOrmuz-graphic-large">
          <Image src="/images/ormuz-graphic-large.png" width={1500} height={664} alt="Devices" />
        </div>
      </div>

      {/* Subtitle */}
      <div className="homeOrmuz-subtitle">
        The Ormuz
      </div>

      {/* Intro */}
      <div className="homeOrmuz-intro">
        A full suite of tools to bridge manufacturing, retail and brands.
      </div>

      {/* Text */}
      <div className="homeOrmuz-text">
        The Ormuz is a combination of a front end e-commerce shop, with back-end b2b tools that combine Shopify, stripe and a manufacturing hub in one company. We make it easy for brands, artists & projects to travel between web2 and web3.
      </div>

      {/* Button */}
      <div className="homeOrmuz-button">
        <Link href="https://ormuz.gobi.so" target="_blank">
          Shop <img src="/images/icons/icon-upright-white.svg" />
        </Link>
      </div>


    </StyledHomeOrmuz>
  );

};

export default HomeOrmuz;



const StyledHomeOrmuz = styled.div<{sectionInView: boolean}>`

  display: block;
  width: 100%;
  padding-top: 3rem;

  .homeOrmuz-video{

    z-index: -1;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* filter: grayscale(100); */
    opacity: 0.1;

    video {

      width: 100%;
      height: 100%;
      object-fit: cover;

      &::-internal-media-controls-overlay-cast-button {
        display: none;
      }

    }

  }

  .homeOrmuz-title{

    h2{
      font-size: 2.5rem;
      line-height: 1.1;
      text-align: center;
    }

  }

  .homeOrmuz-graphic{

    margin-top: 2rem;

    .homeOrmuz-graphic-small{

      display: flex;
      justify-content: center;
      align-items: center;

      img{
        width: 70%;
        max-width: 16rem;
        height: auto;
      }

    }

    .homeOrmuz-graphic-large{
      display: none;
    }

  }

  .homeOrmuz-subtitle{
    margin-top: 2rem;
    font-size: 2rem;
    font-weight: 600;
  }

  .homeOrmuz-intro{
    margin-top: 0.5rem;
    font-size: 1.25rem;
    line-height: 1.2;
  }

  .homeOrmuz-text{
    max-width: 50rem;
    margin-top: 0.75rem;
    color: ${color.gray(66)};
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.4;
  }

  .homeOrmuz-button{

    margin-top: 1.5rem;

    a{

      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 2.5rem;
      padding: 0 3rem;
      color: white;
      font-weight: 750;
      border-radius: 0.25rem;
      background-color: ${color.ormuzGreen(50)};
      transition: all 0.2s ease-in-out;

      &:hover{
        background-color: ${color.ormuzGreen(60)};
      }

      img{
        width: 0.6rem;
        height: 0.6rem;
        margin-left: 0.5rem;
      }

    }

  }



  @media (min-width: ${bp(3)}) {

    padding-top: 6rem;

    .homeOrmuz-title{

      padding-left: 30%;

      h2{
        font-size: 4rem;
        text-align: left;
      }

    }

    .homeOrmuz-graphic{
      
      .homeOrmuz-graphic-small{
        display: none;
      }

      .homeOrmuz-graphic-large{

        display: block;
        max-width: 30rem;

        img{
          width: 100%;
          height: auto;
        }

      }

    }

  } 

  @media (min-width: ${bp(4)}) {

    .homeOrmuz-title{

      padding-left: 40%;

      h2{
        font-size: 5rem;
        line-height: 1;
      }

    }

    .homeOrmuz-graphic{

      .homeOrmuz-graphic-large{
        max-width: 40rem;
      }

    }

  }

  @media (min-width: ${bp(5)}) {

    .homeOrmuz-title{

      h2{
        font-size: 6rem;
      }

    }

    .homeOrmuz-graphic{

      margin-top: 3rem;

      .homeOrmuz-graphic-large{
        max-width: 50rem;
      }

    }

  }

`;
