import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import HomeGobiSmall from 'components/home/gobi/homeGobiSmall';
import HomeGobiLarge from 'components/home/gobi/homeGobiLarge';

import useLayout from 'providers/layout/layoutProvider';

import theme, {bp, color} from 'settings/theme';

type Props = {
  sectionInView: boolean
};

// Component
const HomeGobi: React.FC<Props> = (props) => {

  const router = useRouter();
  const layout = useLayout();

  useEffect(() => {
    if(props.sectionInView){
      layout.setActiveTab('gobi', {scroll: false, changeRouterState: false})
    }
  }, [props.sectionInView]);

  return (
    <StyledHomeGobi id="homeSection-gobi" sectionInView={props.sectionInView} className="homeGobi">

      <div className="homeGobi-small">
        <HomeGobiSmall sectionInView={props.sectionInView} />
      </div>

      <div className="homeGobi-large">
        <HomeGobiLarge sectionInView={props.sectionInView} />
      </div>

    </StyledHomeGobi>
  );

};

export default HomeGobi;



const StyledHomeGobi = styled.div<{sectionInView: boolean}>`

  display: block;
  width: 100%;

  .homeGobi-small{
    display: none;
  }

  .homeGobi-large{
    display: block;
    width: 100%;
  }


  @media (min-width: ${bp(3)}) {

    .homeGobi-small{
      display: none;
    }

    .homeGobi-large{
      display: block;
      width: 100%;
    }

  } 

`;
