import React, { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import Slider from "react-slick";

import theme, {bp, color} from 'settings/theme';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Props = {};

// Component
const HomeMerchantsNfts: React.FC<Props> = (props) => {

  const [activeSlides, setActiveSlides] = useState([1,1,1]);

  const slider1 = React.useRef<Slider>(null);
  const slider2 = React.useRef<Slider>(null);
  const slider3 = React.useRef<Slider>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      slider1.current.slickPause();
      slider2.current.slickPause();
      slider3.current.slickPause();
    }, 100);
  
    const intervalId1 = setInterval(() => {
      slider1.current.slickPlay();
      slider1.current.slickNext();
    }, 2750);
  
    const intervalId2 = setInterval(() => {
      slider2.current.slickPlay();
      slider2.current.slickNext();
    }, 3500);
  
    const intervalId3 = setInterval(() => {
      slider3.current.slickPlay();
      slider3.current.slickNext();
    }, 3000);
  
    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId1);
      clearInterval(intervalId2);
      clearInterval(intervalId3);
    };
  }, []);
  

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    easing: 'ease-out',
    fade: true
  };

  return (
    <StyledHomeMerchantsNfts className="homeMerchantsNfts">

      <div className="homeMerchantsNfts-inner">

        <div className="homeMerchantsNfts-carousel homeMerchantsNfts-carousel-1">
          
          <Slider ref={slider1} {...settings} autoPlaySpeed={12}>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/1.jpg" alt="Merchant 1" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/2.jpg" alt="Merchant 2" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/3.jpg" alt="Merchant 3" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/4.jpg" alt="Merchant 4" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/5.jpg" alt="Merchant 5" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/6.jpg" alt="Merchant 6" width={400} height={400} />
              </div>
            </div>
          </Slider>

        </div>

        <div className="homeMerchantsNfts-carousel homeMerchantsNfts-carousel-2">
          
          <Slider ref={slider2} {...settings} autoPlaySpeed={3500}>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/7.jpg" alt="Merchant 7" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/8.jpg" alt="Merchant 8" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/9.jpg" alt="Merchant 9" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/10.jpg" alt="Merchant 10" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/11.jpg" alt="Merchant 11" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/12.jpg" alt="Merchant 12" width={400} height={400} />
              </div>
            </div>
          </Slider>

        </div>

        <div className="homeMerchantsNfts-carousel homeMerchantsNfts-carousel-3">
            
          <Slider ref={slider3} {...settings} autoPlaySpeed={4000}>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/13.jpg" alt="Merchant 13" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/14.jpg" alt="Merchant 14" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/15.jpg" alt="Merchant 15" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/16.jpg" alt="Merchant 16" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/17.jpg" alt="Merchant 17" width={400} height={400} />
              </div>
            </div>
            <div className="homeMerchantsNfts-slide">
              <div className="homeMerchantsNfts-slide-inner">
                <Image src="/images/merchant-nfts/18.jpg" alt="Merchant 18" width={400} height={400} />
              </div>
            </div>
          </Slider>

        </div>

      </div>

    </StyledHomeMerchantsNfts>
  );
};

export default HomeMerchantsNfts;



const StyledHomeMerchantsNfts = styled.div<{}>`

  display: flex;
  justify-content: flex-end;
  width: 20rem;

  .homeMerchantsNfts-inner{
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
  }  

  .homeMerchantsNfts-carousel{
    position: absolute;
    width: 11rem;
    height: 11rem;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    background-color: black;
  }

  .homeMerchantsNfts-carousel-1{
    top: 0;
    left: 25%;
  }

  .homeMerchantsNfts-carousel-2{
    top: 30%;
    left: 0;
  }

  .homeMerchantsNfts-carousel-3{
    right: 0;
    bottom: 0;
  }

  .homeMerchantsNfts-slide{
    width: 100%;
    height: auto;
  }
  
  .homeMerchantsNfts-slide-inner{

    width: 100%;
    height: auto;

    img{
      width: 100%;
      height: 100%;
    }

  }

  @media (min-width: ${bp(5)}) {

    width: 28rem;

    .homeMerchantsNfts-carousel{
      width: 16rem;
      height: 16rem;
    }

  } 

`;
